<template>
    <div class="home">
		<div class="page-1" :style="pageOneHeight">
			<div class="page-1-con">
				<span class="togglePlayMusic" @click="awaken">
					<img v-if="off" src="@/assets/img/dissDao/music_off.png" alt="">
					<img v-else src="@/assets/img/dissDao/music.png" alt="">
				</span>

				<span class="go-second" @click="goSecond">
					<img width="200" src="@/assets/img/dissDao/1/img_01.png" alt="">
				</span>
			</div>
		</div>
		<div class="page-2">
			<el-image :src="page2Img" fit="cover" />
		</div>
		<div class="page-3">
			<el-image :src="page3Img" fit="cover" />
		</div>
    </div>
</template>

<script>
	import page2Img from './../../assets/img/dissDao/1/7.png';
	import page3Img from './../../assets/img/dissDao/1/PNG/3.png';
	export default {
		name: 'Home',
		data() {
			return {
				page2Img,
				page3Img,
				// off: false, // 音乐开关
				pageOneHeight:{},

			}
		},
		computed: {
			off() {
				return this.$store.state.off;
			}
		},
		methods: {
			awaken(){
				this.$store.dispatch('awaken')
			},
			goSecond(){
				this.$router.push('second')
			},
			getHeight(){
				const body_h = document.getElementsByTagName('body')[0].clientHeight;
				this.pageOneHeight = {height:body_h+'px'}
			}
		},
		mounted () {
			this.getHeight();
		},
	}
</script>
<style lang="less" scoped>
	.home{
		width: 100%;
		min-height: 100vh;
		min-width: 1200px;
		background: #333;
		.page-1{
			position: relative;
			background: url('./../../assets/img/dissDao/1/1..png') no-repeat center;
			background-size: cover;
			&-con{
				width: 100%;
				min-height: 100vh;
				background: url('./../../assets/img/dissDao/1/5..gif') no-repeat center;
				background-size: cover;
			}
			.img-1{
				width: 100vw;
				min-height: 100vh;
				position: absolute;
				top: 0;
				left: 0;
			}
			.togglePlayMusic{
				position: absolute;
				width: 3.9vw;
				height: 3.9vw;
				right: 55px;
                bottom: 20px;
				cursor: pointer;
				img{
					width: 3.9vw;
					height: 3.9vw;
				}
			}
			.go-second{
				position: absolute;
				right: 20px;
				top: 10px;
				cursor: pointer;
			}
		}
		.page-2{
			display: flex;
			.img-1{
				width: 100vw;
				min-height: 100vh;
			}
		}
		.page-3{
			background: url('./../../assets/img/dissDao/1/PNG/2.png') no-repeat center, url('./../../assets/img/dissDao/1/PNG/1.png') no-repeat center;
			background-size: cover;
			background-position-x: 1px;
		}
	}
</style>
